import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './responsive-carousel.scss';

const ResponsiveCarousel = ({ children, ...props }) => {
    return (
        <Carousel {...props}>
            {children}
        </Carousel>
    );
};

export default ResponsiveCarousel;