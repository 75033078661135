import React from 'react';
import LogoSvg from '../../../images/brand/kleiner-perkins.svg';
import styles from './header.module.scss';

const header = ({ ctaText, ctaLink }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.logo_wrapper}>
                <a href="https://kleinerperkins.com" target='_blank'>
                    <LogoSvg />
                </a>
            </div>
            <div className={styles.cta_wrapper}>
                <a href={ctaLink}>{ctaText}</a>
            </div>
        </div>
    );
};

export default header;