import React from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './benefits.module.scss';

const benefits = ({ heroTitle, heroSubtitle, heroBulletList }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.title_wrapper}>
                <h2 className={styles.title}>{heroTitle}</h2>
                <ReactMarkdown className={styles.subtitle} source={heroSubtitle} escapeHtml={false} />
            </div>
            <div className={styles.list_grid}>
                {heroBulletList.map((item, index) => (
                    <div className={styles.list_grid_item} key={`benefit-${index}`}>
                        <h4>{item.title}</h4>
                        <p>{item.subText}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default benefits;