import React from 'react';
import NextSvg from '../../../images/icons/next.svg';
import styles from './final-hero.module.scss';

const Finalhero = ({ ctaText, ctaLink }) => {
    return (
        <div className={styles.wrapper}>
            <NextSvg />
            <div className="next-apply">
                
                <div className='text'>
                    Unlock the next big phase of your career. Supercharge your skills, network, and opportunities with <span> KP Next.</span>  
                </div>
                
                <a href={ctaLink} className={styles.btn}>{ctaText}</a>
            </div>

        </div>
    );
};

export default Finalhero;