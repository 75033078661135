import React from 'react';
import { buildImageObj, imageUrlFor } from '../../sanity/image-url';
import styles from './portfolio-companies.module.scss';

const IconWrapper = (props) => (
  <div className={styles.icon_wrapper} {...props}>
    {props.url ? (
      <a href={props.url}>{props.children}</a>
    ) : (
      props.children
    )}
  </div>
);

const PortfolioCompanies = ({ companies }) => {
  const limitedCompanies = companies.slice(0, 20);

  return (
    <div className={styles.wrapper}>
      <h2>
        Portfolio Partners
      </h2>
      <div className={styles.companies_grid_wrapper}>
        {limitedCompanies.map((company, index) => (
          <IconWrapper
            key={`next-featured-company-${index}`}
            url={company.url}
          >
            <img
              src={imageUrlFor(buildImageObj(company.image))}
              alt={company.altText}
            />
          </IconWrapper>
        ))}
      </div>
    </div>
  );
};

export default PortfolioCompanies;
