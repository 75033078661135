/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import CheckSvg from '../../../images/icons/check.svg';
import styles from './how-to-join-callout.module.scss';

const HowToJoinCallout = ({ title, subtitle, ctaText, ctaLink, listTitle, listItems }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.inner_wrapper}>
                <div className={styles.title_wrapper}>
                    <h2>{title}</h2>
                    <ReactMarkdown className={styles.subtitle} source={subtitle} escapeHtml={false} />
                    <a href={ctaLink} className={`${styles.btn}`}>{ctaText}</a>
                </div>
                <div className={styles.list_wrapper}>
                    <h4>{listTitle}</h4>
                    <div className={styles.list_grid_wrapper}>
                        {listItems.map((item, index) => (
                            <div className={styles.list_item} key={`how-to-join-list-item-${index}`}>
                                <CheckSvg />
                                <p>{item}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowToJoinCallout;
