import React from 'react';
import NextSvg from '../../../images/icons/next.svg';
import styles from './fixed-hero.module.scss';

const FixedHero = () => {
    return (
        <>
            <div className={styles.wrapper}>
                <NextSvg />
                <div className={styles.text_wrapper}>
                    <div className={styles.divider_line} />
                    <p>
                        Unlock the next big phase of your career
                    </p>
                </div>
            </div>
        </>
    );
};

export default FixedHero;
