import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ lang, meta, keywords, title, bodyClasses, bodyId }) {

    const data = useStaticQuery(graphql`
  query SEOQuery {
    site: sanitySiteSettings {
      title
      keywords
    }
  }
`);

    // const metaDescription = description || (data.site && data.site.description) || '';
    const siteTitle = (data.site && data.site.title) || '';
    let bodyAttributes = {};
    bodyClasses ? bodyAttributes.class = bodyClasses : null;
    bodyId ? bodyAttributes.id = bodyId : null;

    return (
        <Helmet
            htmlAttributes={{ lang }}
            title={"Next"}
            titleTemplate={title === siteTitle ? '%s' : `%s | Unlock the next big phase of your career. Supercharge your skills, network, and opportunities with KP Next.`}
            bodyAttributes={bodyAttributes}
            meta={[
                {
                    name: 'description',
                    content: 'Unlock the next big phase of your career. Supercharge your skills, network, and opportunities with KP Next.'
                },
                {
                    property: 'og:title',
                    content: 'Kleiner Perkins Next'
                },
                {
                    property: 'og:description',
                    content: 'Unlock the next big phase of your career. Supercharge your skills, network, and opportunities with KP Next.'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:image',
                    content: 'https://imagedelivery.net/gGeEaec7sZve6xpqh5B7_g/b56b15a5-7272-4840-9be4-2f2bc7f69600/w=600'
                },
                {
                    property: 'og:url',
                    content: 'https://next.kleinerperkins.com'
                },
                {
                    name: 'twitter:card',
                    content: 'summary'
                },
                {
                    name: 'twitter:creator',
                    content: 'Kleiner Perkins Next'
                },
                {
                    name: 'twitter:title',
                    content: 'Kleiner Perkins Next'
                },
                {
                    name: 'twitter:description',
                    content: 'Unlock the next big phase of your career. Supercharge your skills, network, and opportunities with KP Next.'
                },
                {
                    name: 'twitter:image',
                    content: 'https://imagedelivery.net/gGeEaec7sZve6xpqh5B7_g/b56b15a5-7272-4840-9be4-2f2bc7f69600/w=200'
                },
                {
                    name: 'linkedin:image',
                    content: 'https://imagedelivery.net/gGeEaec7sZve6xpqh5B7_g/b56b15a5-7272-4840-9be4-2f2bc7f69600/w=200'
                },
                {
                    name: 'facebook:image',
                    content: 'https://imagedelivery.net/gGeEaec7sZve6xpqh5B7_g/b56b15a5-7272-4840-9be4-2f2bc7f69600/w=200'
                }
            ]
                .concat(
                    keywords && keywords.length > 0
                        ? {
                            name: 'keywords',
                            content: keywords.join(', ')
                        }
                        : []
                )
                .concat(meta)}/>

    );
}

SEO.defaultProps = {
    lang: 'en',
    meta: [],
    keywords: []
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired
};

export default SEO;
